//mobile menu click function
// $(".mobile-menu-bar").click(function () {
//     $(".menu-links-mobile").addClass("show-menu");
//     $("body").addClass("no-scroll");
// })
if(document.querySelectorAll(".mobile-menu-bar").length > 0){
    document.querySelectorAll(".mobile-menu-bar").forEach(function (element) {
        element.addEventListener("click", function () {
            document.querySelector(".menu-links-mobile").classList.add("show-menu");
            document.body.classList.add("no-scroll");
        });
    });
}

// $(".close-icon").click(function () {
//     $(".menu-links-mobile").removeClass("show-menu");
//     $("body").removeClass("no-scroll");
// })
if(document.querySelectorAll(".close-icon").length > 0){
    document.querySelectorAll(".close-icon").forEach(function (element) {
        element.addEventListener("click", function () {
            var selectorLink =   document.querySelector(".menu-links-mobile");
            if(selectorLink){
                selectorLink.classList.contains("show-menu").remove("show-menu");
            }
            document.body.classList.contains("no-scroll").remove("no-scroll");
        });
    });
}

// var winwidth = $(window).width();
// if (winwidth < 992) {
//     //Explore  mobile humbarger open
//     $(".mobile-humbarger").on('click', function () {
//         var icon = $(this).find("i.far");
//         icon.removeClass("fa-times");
//         $(this).parents(".topHeader").find(".mega-menu-wrap").toggleClass("show");
//         $(this).parents(".topHeader").find(".searchBtn").toggleClass("hide");
//         $(this).parents(".topHeader").find(".navTop").toggleClass("height-90");
//         if (icon.hasClass("fa-bars")) {
//             icon.removeClass("fa-bars").addClass("fa-times")
//         }
//         else {
//             icon.addClass("fa-bars");
//         }
//     });
// }
var winwidth = window.innerWidth;
if (winwidth < 992) {
    // Explore mobile humbarger open
    document.querySelector(".mobile-humbarger").addEventListener("click", function () {
        var icon = this.querySelector("i.far") || this.querySelector("i.ficon");
        icon.classList.remove("fa-times");
        var megaMenuWrap = this.closest(".topHeader").querySelector(".mega-menu-wrap");
        var searchBtn = this.closest(".topHeader").querySelector(".searchBtn");
        var navTop = this.closest(".topHeader").querySelector(".navTop");
        var searchSection = this.closest(".topHeader").querySelector(".search-section");
        megaMenuWrap.classList.toggle("show");
        searchBtn.classList.toggle("hide");
        navTop.classList.toggle("height-90");
        searchSection.classList.remove('is-active');
        searchBtn.querySelector('.ficon').classList.remove('fa-times');
        searchBtn.querySelector('.ficon').classList.add('fa-search');

        if (icon.classList.contains("fa-bars")) {
            icon.classList.remove("fa-bars");
            icon.classList.add("fa-times");
        } else {
            icon.classList.add("fa-bars");
        }
    });
}

//Explore serch bar

document.querySelector(".searchBtn").addEventListener("click", function (event) {
    event.stopPropagation();
    var sIcon = this.querySelector("i.far") || this.querySelector("i.ficon");
    sIcon.classList.remove("fa-times");
    var searchSection = this.closest(".topHeader").querySelector(".search-section");

    searchSection.classList.toggle("is-active");
    document.getElementById('wpSearchBox').value = "";
    document.getElementById('wpSearchResult').classList.remove("show-serch-dropdown");

    if (sIcon.classList.contains("fa-search")) {
        sIcon.classList.remove("fa-search");
        sIcon.classList.add("fa-times");
    } else {
        sIcon.classList.add("fa-search");
    }
});


// $(".searchBtn").on('click', function (event) {
//     event.stopPropagation();
//     var sIcon = $(this).find("i.far");
//     sIcon.removeClass("fa-times");
//     $(this).parents(".topHeader").find(".search-section").toggleClass("is-active");
//     document.getElementById('wpSearchBox').value = "";
//     document.getElementById('wpSearchResult').classList.remove("show-serch-dropdown");
//     if (sIcon.hasClass("fa-search")) {
//         sIcon.removeClass("fa-search").addClass("fa-times")
//     }
//     else {
//         sIcon.addClass("fa-search");
//     }
// });

// document.body.addEventListener("click", function (e) {
//     var container = document.querySelector(".search-section");
//     var oIcon = document.querySelector(".searchBtn .fa-times");

//     if (!container.contains(e.target)) {
//         container.classList.remove("is-active");
//         document.getElementById('wpSearchBox').value = "";
//         document.getElementById('wpSearchResult').classList.remove("show-serch-dropdown");
//         oIcon.classList.remove("fa-times");
//         oIcon.classList.add("fa-search");
//     }
// });

// $("body").click(function (e) {
//     var container = $(".search-section");
//     var oIcon = $(".searchBtn .fa-times");
//     if (!container.is(e.target) && container.has(e.target).length === 0) {
//         $(".search-section").removeClass("is-active");
//         document.getElementById('wpSearchBox').value = "";
//         document.getElementById('wpSearchResult').classList.remove("show-serch-dropdown");
//         oIcon.removeClass("fa-times").addClass("fa-search")
//     }
// });


var isSessionStorageAvailable = function () {
    var testKey = 'test', storage = window.sessionStorage;
    try {
        if (storage) {
            storage.setItem(testKey, '1');
            storage.removeItem(testKey);
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}
function setTemplate(skinCD) {
    if (isSessionStorageAvailable()) {
        sessionStorage.setItem('selectedSkinCD', skinCD);
    }
    window.location.href = "/builder/how-it-works";
}

function searchWP(e) {
    e.preventDefault();
    searchArticles(document.getElementById('wpSearchBox').value);
}
function searchArticles(text) {
    if (text && text.length > 2) {
        var url = "/home/searcharticles" + "?text=" + text + "&num=3";
        if (window.XMLHttpRequest) {
            // code for modern browsers
            var xmlhttp = new XMLHttpRequest();
        } else {
            // code for old IE browsers
            var xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
        }
        xmlhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                var response = JSON.parse(xmlhttp.responseText);
                var searchResultHTML = '';
                if (response.length > 0) {
                    document.getElementById('wpSearchResult').classList.remove("no-result-dropdown");
                    searchResultHTML = '<ul>'
                    for (var i = 0; i < response.length; i++) {
                        searchResultHTML += '<li><a target="_blank" href = "' + response[i].slug + '"><div class="search-list-img"><img src = "' + response[i].feautured_image.thumbnail[0] + '" alt = "tips image" height="120" width="120" /></div><div class="serch-list-text"><h3>'
                            + response[i].title + '</h3><p>' + response[i].description + '</p></div></a></li>';
                    }
                    searchResultHTML += '</ul>';
                }
                else {
                    document.getElementById('wpSearchResult').classList.add("no-result-dropdown");
                    searchResultHTML = '<p class="searchResultNotFount">Sorry, no results found</p>';
                }
                document.getElementById('wpSearchResult').innerHTML = searchResultHTML;
                document.getElementById('wpSearchResult').classList.add("show-serch-dropdown");
            }
        };
        xmlhttp.open("GET", url, true);
        xmlhttp.send();
    }
    else {
        document.getElementById('wpSearchResult').innerHTML = '';
        document.getElementById('wpSearchResult').classList.remove("show-serch-dropdown");
    }
}


$(function () {
    const els = document.querySelectorAll(`a[href$="how-it-works"]`);
    var params = 0;
    if (els.length === 0) {
        return;
    }

    function add_query(el) {
        if (!el.href) {
            return;
        }

        el.addEventListener(
            `click`,
            () => {

                if (window.location.href.indexOf('?') >= 0) {
                    params = window.location.href.slice(window.location.href.indexOf('?') + 1);
                }
                if (el.href.indexOf('?') < 0) {
                    el.href = el.href + ((params.length > 0) ? ('?' + params) : '');
                }
            }
        );
    }

    for (let i = 0; i < els.length; i++) {
        add_query(els[i]);
    }
});

//Multiple options dropdown

// const optionMenu = document.querySelector(".select-menu"),
//   selectBtn = optionMenu.querySelector(".select-btn"),
//   options = optionMenu.querySelectorAll(".option"),
//   sBtn_text = optionMenu.querySelector(".sBtn-text"),
//   sBtn_img = optionMenu.querySelector(".sBtn-img");
// selectBtn.addEventListener("click", () =>
//   optionMenu.classList.toggle("active")
// );

// options.forEach((option) => {
//   option.addEventListener("click", () => {
//     let selectedOption = option.querySelector(".option-text").innerText;
//       sBtn_text.innerText = selectedOption;

//     let selectedOptionImg = option.querySelector(".option-img").innerHTML;

//     sBtn_img.innerHTML = selectedOptionImg;

//     optionMenu.classList.remove("active");
//   });
// });



// new Div Element created based on video Element present in Hero banner
var isVideo = document.querySelector(".hero-banner .is-video");
if (isVideo != null){
    var videoSpan = isVideo.querySelector("#video");
        isVideo.querySelector("#video").addEventListener("click", function(e) {
         var videoLink = videoSpan.getAttribute('data-modal-video');
         var ctamodal = document.getElementById("cta-modalvideo");
        
         var vidSource = document.createElement("source");
         vidSource.setAttribute('id', "vidSource");
         vidSource.setAttribute('src', videoLink);
         vidSource.setAttribute('type', 'video/mp4');
        var videoModal = ctamodal.querySelector("#videoModal");
        videoModal.appendChild(vidSource);
        videoModal.play();
        ctamodal.style.display = "block";

        var closecta = document.getElementsByClassName("close")[0];

        closecta.onclick = function () {
            videoModal.pause();
            ctamodal.style.display = "none";
        }
        document.body.onclick = function (event) {
            if (event.target == ctamodal) {
                videoModal.pause();
                ctamodal.style.display = "none";
            }
        }
   
   });

// =============================================================================



// ==============  videoModal =====================
//        function openVideo() {
//            var ctamodal = document.getElementById("cta-modalvideo");
//           document.getElementById("videoModal").play();
//            ctamodal.style.display = "block";          
//         }
//         var videoModal = document.getElementById("videoModal");
//         var check_controls_list = videoModal.hasAttribute('controlsList');
//         var check_pip = videoModal.hasAttribute('disablePictureInPicture');
//         if (!check_controls_list){
//             videoModal.setAttribute('controlsList', 'nodownload');
//         }
//         if (!check_pip){
//             videoModal.setAttribute('disablePictureInPicture', 'true');
//         }
//         var ctamodal = document.getElementById("cta-modalvideo");
//         var closecta = document.getElementsByClassName("close")[0];

//         closecta.onclick = function () {
//             document.getElementById("videoModal").pause();
//             ctamodal.style.display = "none";
//         }
//         document.body.onclick = function (event) {
//             if (event.target == ctamodal) {
//                 document.getElementById("videoModal").pause();
//                 ctamodal.style.display = "none";
//             }
//         }
   
//             // document.getElementById('vidSource').src = 'https://rncdn1ak.azureedge.net/video/Nerd-DemoVideo.mp4';
//     document.querySelector("video").load();

} 


function openVideo() {
    var ctamodal = document.getElementById("cta-modalvideo");
    var videoModal = document.getElementById("videoModal");
    document.getElementById("videoModal").play();
    ctamodal.style.display = "block";
    var check_controls_list = videoModal.hasAttribute('controlsList');
    var check_pip = videoModal.hasAttribute('disablePictureInPicture');
    if (!check_controls_list){
        videoModal.setAttribute('controlsList', 'nodownload');
    }
    if (!check_pip){
        videoModal.setAttribute('disablePictureInPicture', 'true');
    }
}

$('.video-btn').hover(function () {
    var ctamodal = document.getElementById("cta-modalvideo");
    var closecta = document.getElementById("vidclose");
    closecta.onclick = function () {
        document.getElementById("videoModal").pause();
        ctamodal.style.display = "none";
    };
    document.body.onclick = function (event) {
        if (event.target == ctamodal) {
            document.getElementById("videoModal").pause();
            ctamodal.style.display = "none";
        }
    };
    var videSource = document.getElementById("vidSource");
    videSource.src = videSource.getAttribute('data-src');
    document.querySelector("video").load();
});

// Sub categories 
var categoriesCardWrapper = document.getElementsByClassName("categories-card-wrapper");
if (categoriesCardWrapper != null && categoriesCardWrapper.length > 0) {
    var stickySection = document.querySelector(".categories-sidebar"),
        navBar = document.querySelector(".topHeader").offsetHeight,

        stickyFixTop = resumeGetOffset(stickySection).top - navBar,
        staticStickyOffset = resumeGetOffset(stickySection).top;
    function resumeGetOffset(elem) {
        var rect = elem.getBoundingClientRect(),
            doc = elem.ownerDocument,
            docElem = doc.documentElement,
            win = doc.defaultView;
        return {
            top: rect.top + win.pageYOffset - docElem.clientTop,
            left: rect.left + win.pageXOffset - docElem.clientLeft
        };
    }
    function fixedCategoriesSidebar() {
        var w_width = document.documentElement.clientWidth || window.innerwidth;
        pageScroll = window.pageYOffset,
            goTopWithSticky = resumeGetOffset(document.querySelector(".go-top-wrapper")).top,
            stickySectionHeight = stickySection.offsetHeight,
            stickyFixBottom = goTopWithSticky - (staticStickyOffset + navBar + stickySectionHeight + 20);

        if (w_width > 767) {
            if (pageScroll >= staticStickyOffset - navBar) {
                stickySection.classList.add('sticky-sidebar');
                stickySection.style.top = (navBar + 10) + 'px';
            }
            else {
                stickySection.classList.remove('sticky-sidebar');
                stickySection.style.top = "auto";
            }
            if ((goTopWithSticky - (stickySectionHeight + navBar + 60)) < pageScroll) {
                stickySection.classList.add('go-top-with-sticky');
                stickySection.style.transform = "translate3d(0," + (stickyFixBottom) + "px, 0)";
                stickySection.style.top = "auto";
            }
            else {
                stickySection.classList.remove("go-top-with-sticky");
                stickySection.style.transform = "none";
            }
        }

    }
    window.addEventListener('scroll', function () {
        fixedCategoriesSidebar();

    });

}



function scrollingTop(elementY, duration) {
    var startingY = window.pageYOffset;
    var diff = elementY;
    var start;
    window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        var time = timestamp - start;
        var percent = Math.min(time / duration, 1);
        window.scrollTo(0, startingY + diff * percent);
        if (time < duration) {
            window.requestAnimationFrame(step);
        }
    })
}

var categoryWrapperId = document.querySelectorAll("#category-wrapper");
if (typeof categoryWrapperId != 'undefined' && categoryWrapperId != null && categoryWrapperId.length > 0) {
    var categoryLink = document.querySelectorAll('.category-link');
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    for (let i = 0; i < categoryLink.length; i++) {
        categoryLink[i].addEventListener('click', function (e) {
            if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            }
            else {
                e.preventDefault();
            }
            e.preventDefault();
            var href = e.target.getAttribute('href');
            var id = href.substr(1);
            var target = document.getElementById(id);
            var top = target.getBoundingClientRect().top;
            var topsum;
            var headerHeight = document.querySelector('.topHeader').offsetHeight;

            topsum = top - headerHeight - 10;
            scrollingTop(topsum, 150);
        });
    }

    var goTopWrapper = document.querySelector('.go-top-wrapper a');
    goTopWrapper.addEventListener('click', function (e) {
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        }
        else {
            e.preventDefault();
        }
        e.preventDefault();
        var href = e.target.getAttribute('href');
        var id = href.substr(1);
        var target = document.getElementById(id);
        var top = target.getBoundingClientRect().top;
        var topsum;
        var headerHeight = document.querySelector('.topHeader').offsetHeight;
        topsum = top - headerHeight - 10;
        scrollingTop(topsum, 150);
    });
}